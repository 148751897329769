// src/App.js
import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import ProductBoosterLandingPage from './components/ProductBoosterLandingPage';
import { useTranslation } from 'react-i18next';



function App() {
  const { t, i18n } = useTranslation();

  // You can dynamically set the document title based on language
  React.useEffect(() => {
    document.title = t('hero.title');
  }, [t, i18n.language]);

  return (
    <ThemeProvider theme={theme}>
      <ProductBoosterLandingPage />
    </ThemeProvider>
  );
}

export default App;
