import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
    AppBar,
    Badge,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Container,
    Divider,
    Fab,
    Grid,
    IconButton,
    InputAdornment,
    Slide,
    Snackbar,
    Stack,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
    Zoom,
    Fade,
    useMediaQuery,
    useTheme,
    createTheme,
    ThemeProvider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    ListItemText,
    FormGroup
  } from '@mui/material';
  import {
    ArrowForward,
    AutoAwesome,
    ExpandMore,
    EmailOutlined,
    Check,
    CheckCircle,
    CloudUpload,
    Code,
    CompareArrows,
    CreditCard,
    Devices,
    East,
    EmojiEvents,
    FastForward,
    FileUpload,
    Image,
    KeyboardArrowUp,
    LocalOffer,
    Lock,
    Message,
    PlayArrow,
    Send,
    ShoppingCart,
    Star,
    Timeline,
    Verified,
    WhatsApp,
    ArrowBackIos,
    ArrowForwardIos,
  } from '@mui/icons-material';
  import { styled } from '@mui/system';
import MediaGrid from './MediaGrid';
  

import { BeforeAfterImage, GradientText, HeroGradientBox, SliderDivider, StyledFab } from './styledComponents';
import ContactForm from './ContactForm';
import FAQ from './FAQ';
import Footer from './Footer';
import { MobileMenu } from './MobileMenu';
import LanguageSwitcher from './LanguageSwitcher';

// Custom styled components


const ProductBoosterLandingPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isMedium = useMediaQuery(theme.breakpoints.down('md'));
  const [sliderPosition, setSliderPosition] = useState(50);
  const [activeTab, setActiveTab] = useState(0);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [videoDialog, setVideoDialog] = useState(false);
  const [sliderPositions, setSliderPositions] = useState({
    1: 50,
    2: 50,
    3: 50
  });

  // Stats counter animation
  const [stats, setStats] = useState({
    conversionRate: 0,
    timesSaved: 0,
    happyCustomers: 0
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    options: [], // Array for multiple optimizations
    quantity: '',
    deliveryTime: '',
    imageUrls: '',
    description: '',
    termsAgreed: false
  });

  // Loading and error states
  const [isLoading, setIsLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [formSuccess, setFormSuccess] = useState(false);



  // Handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      setShowScrollTop(window.scrollY > 500);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Animate stats when in viewport
  useEffect(() => {
    const timer = setTimeout(() => {
      setStats({
        conversionRate: 40,
        timesSaved: 85,
        happyCustomers: 3578
      });
    }, 1000);
    
    return () => clearTimeout(timer);
  }, []);

  // Simulate image loading
  useEffect(() => {
    const timer = setTimeout(() => {
      setImageLoaded(true);
    }, 500);
    
    return () => clearTimeout(timer);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  // Sample before/after image pairs with improved images
  const beforeAfterImages = [
    {
      id: 1,
      before: '/assets/demo-0-before.jpg',
      after: '/assets/demo-0-after.png',
      title: t("demos.examples.0.title"),
      improvement: t("demos.examples.0.improvement")
    },
    {
      id: 2,
      before: '/assets/demo-1-before.jpg',
      after: '/assets/demo-1-after.png',
      title: t("demos.examples.1.title"),
      improvement: t("demos.examples.1.improvement")
    },
    {
      id: 3,
      before: '/assets/demo-2-before.jpg',
      after: '/assets/demo-2-after.png',
      title: t("demos.examples.2.title"),
      improvement: t("demos.examples.2.improvement")
    },
  ];

  const benefitItems = t('benefitsList.items', { returnObjects: true });
  const iconMap = [
    <ShoppingCart fontSize="large" color="primary" />,
    <FastForward fontSize="large" color="primary" />,
    <CreditCard fontSize="large" color="primary" />,
    <Timeline fontSize="large" color="primary" />
  ];
  
  // Advanced benefits
  const benefits = benefitItems.map((item, index) => ({
    id: index + 1,
    title: item.title,
    description: item.description,
    icon: iconMap[index],
    stats: item.stats
  }));

 
// How it works steps (updated content)
const workflowSteps = [
    {
      title: t("howItWorks.steps.0.title"),
      description: t("howItWorks.steps.0.description"),
      icon: <Send fontSize="large" color="primary" />
    },
    {
      title: t("howItWorks.steps.1.title"),
      description: t("howItWorks.steps.1.description"),
      icon: <Image fontSize="large" color="primary" />
    },
    {
      title: t("howItWorks.steps.2.title"),
      description: t("howItWorks.steps.2.description"),
      icon: <CheckCircle fontSize="large" color="primary" />
    },
    {
      title: t("howItWorks.steps.3.title"),
      description: t("howItWorks.steps.3.description"),
      icon: <AutoAwesome fontSize="large" color="primary" />
    }
  ];

  return (
    <Box sx={{ overflow: 'hidden', position: 'relative' }}>
  <AppBar position="fixed" color="default" elevation={0} sx={{ 
  backdropFilter: 'blur(20px)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)' 
}}>
  <Container>
    <Box display="flex" justifyContent="space-between" alignItems="center" py={1.5}>
      <Box display="flex" alignItems="center">
        <AutoAwesome color="primary" sx={{ mr: 1 }} />
        <GradientText variant="h5" component="h1" fontWeight="bold">
          Product Booster
        </GradientText>
      </Box>
      
      {/* Desktop Navigation */}
      <Box 
            sx={{ 
              mx: 4, 
              display: { xs: 'none', md: 'flex' },
              alignItems: 'center'
            }}
          >
              <Button color="inherit" sx={{ mx: 1 }} href='#benefits'>
              {t('navigation.benefits')}
            </Button>
            <Button color="inherit" sx={{ mx: 1 }} href='#how-it-works'>
              {t('navigation.howItWorks')}
            </Button>
            <Button color="inherit" sx={{ mx: 1 }} href='#demos'>
              {t('navigation.examples')}
            </Button>
            <Button color="inherit" sx={{ mx: 1 }} href='#contact'>
              {t('navigation.contact')}
            </Button>
      
                    {/* Language Switcher for Desktop */}
                    <LanguageSwitcher variant="flag" />
      </Box>
      
      {/* Mobile Menu with Language Switcher */}
      <Box display="flex" alignItems="center" sx={{ display: { md: 'none' } }}>
            {/* Language Switcher for Mobile */}
            <LanguageSwitcher variant="icon" />
            
            <MobileMenu
              menuItems={[
                { label: t('navigation.benefits'), href: '#benefits' },
                { label: t('navigation.howItWorks'), href: '#how-it-works' },
                { label: t('navigation.examples'), href: '#demos' },
                { label: t('navigation.contact'), href: '#contact' }
              ]} 
            />
          </Box>
    </Box>
  </Container>
</AppBar>
      
      {/* Hero Section with animation */}
   {/* Hero Section with animation */}
<Box sx={{ pt: 12, pb: { xs: 8, md: 10 }, position: 'relative' }}>
  <Container maxWidth="lg">
    
        <Zoom in={true} timeout={1000}>
        <Box>
  <Badge 
    badgeContent={
      <Chip 
        label={t("hero.badge")}
        color="secondary" 
        size="small" 
        sx={{ 
          fontWeight: 'bold',
          animation: 'pulse 2s infinite'
        }}
      />
    }
    sx={{ mb: 2 }}
  >
    <GradientText 
      variant={isMobile ? "h3" : "h2"} 
      component="h1" 
      fontWeight="bold" 
      gutterBottom
      sx={{ lineHeight: 1.2 }}
    >
      {t("hero.title")}
    </GradientText>
  </Badge>
  
  <Grid container spacing={3} sx={{ mt: 1 }}>
    <Grid item xs={12} sm={12} md={6}>
      <Typography 
        variant="h6" 
        component="h2" 
        paragraph 
        sx={{ 
          mb: 2,
          color: 'text.secondary',
          fontWeight: 400
        }}
      >
       {t("hero.subtitle")}
      </Typography>
      
      <Stack direction={isMobile ? "column" : "row"} spacing={2} sx={{ mb: 3 }}>
        <Button 
          variant="contained" 
          size="large" 
          color="primary" 
          href="#contact" // Link to contact section
          sx={{ 
            py: 1.5, 
            px: 3,
            fontSize: '0.9rem',
            fontWeight: 'bold',
            boxShadow: '0 8px 20px rgba(0, 120, 255, 0.3)',
            position: 'relative',
            overflow: 'hidden',
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              left: '-100%',
              width: '100%',
              height: '100%',
              background: 'linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)',
              animation: 'shine 3s infinite',
            },
            '@keyframes shine': {
              '0%': { left: '-100%' },
              '60%': { left: '100%' },
              '100%': { left: '100%' }
            }
          }}
          startIcon={<FileUpload />}
        >
          {t("hero.cta.primary")}
        </Button>
        
        <Button 
          variant="outlined" 
          size="large" 
          color="primary"
          startIcon={<PlayArrow />}
          href="#demos" // Link to demos section
          sx={{ 
            py: 1.5, 
            px: 3,
          }}
        >
         {t("hero.cta.secondary")}
        </Button>
      </Stack>
      
      {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', mr: 2 }}>
          {[1, 2, 3, 4, 5].map((_, index) => (
            <Box 
              key={index}
              component="img"
              src={`https://randomuser.me/api/portraits/women/${index + 30}.jpg`}
              alt="User"
              sx={{ 
                width: 28, 
                height: 28, 
                borderRadius: '50%', 
                border: '2px solid white',
                marginLeft: index > 0 ? -1.2 : 0,
                boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
              }}
            />
          ))}
        </Box>
        <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.85rem' }}>
          <b>3,500+ zufriedene</b> Kunden
        </Typography>
      </Box> */}
    </Grid>
  </Grid>
</Box>


     

    </Zoom>
    <MediaGrid/>
    </Container>

</Box>

     
    {/* Problem/Solution Section with modern, lightweight design */}
<Box id="benefits" sx={{ bgcolor: 'background.paper', py: 8 }}>
  <Container>
    <Box sx={{ mb: 6, textAlign: 'center' }}>
      <Typography 
        variant="h4" 
        component="h2" 
        fontWeight="bold" 
        gutterBottom
      >
      {t("benefits.title")}
      </Typography>
      <Typography 
        variant="body1" 
        color="text.secondary" 
        sx={{ maxWidth: 700, mx: 'auto' }}
      >
    {t("benefits.subtitle")}
      </Typography>
    </Box>
    
    <Grid container spacing={4} alignItems="stretch" justifyContent="center">
      {/* PROBLEM COLUMN */}
      <Grid item xs={12} md={6}>
        <Box 
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3
            }}
          >
            <Box 
              sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                border: "1px solid",
                borderColor: 'error.dark', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                mr: 2,
                flexShrink: 0
              }}
            >
              <Typography variant="subtitle1" color="error.dark">!</Typography>
            </Box>
            <Typography variant="h5" component="h3" fontWeight="medium">
            {t("benefits.problem.title")}
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />
          
          <Stack spacing={2} sx={{ mb: 'auto' }}>
            {[
              t("benefits.problem.items.0"),
              t("benefits.problem.items.1"),
              t("benefits.problem.items.2"),
              t("benefits.problem.items.3"),
              t("benefits.problem.items.4")
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start'
                }}
              >
                <Box 
                  component="span" 
                  sx={{ 
                    color: 'error.main', 
                    mr: 1.5,
                    mt: 0.3,
                    flexShrink: 0
                  }}
                >
                  ✕
                </Box>
                <Typography variant="body2">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Grid>

      {/* SOLUTION COLUMN */}
      <Grid item xs={12} md={6}>
        <Box 
          sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: { xs: 'none', md: '1px solid' },
            borderColor: { xs: 'transparent', md: 'divider' },
            pl: { xs: 0, md: 4 }
          }}
        >
          <Box 
            sx={{ 
              display: 'flex', 
              alignItems: 'center', 
              mb: 3
            }}
          >
            <Box 
              sx={{ 
                width: 40, 
                height: 40, 
                borderRadius: '50%', 
                border: "1px solid",
                borderColor: 'success.dark', 
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                mr: 2,
                flexShrink: 0
              }}
            >
              <Typography variant="subtitle1" color="success.dark">✓</Typography>
            </Box>
            <Typography variant="h5" component="h3" fontWeight="medium">
            {t("benefits.solution.title")}
            </Typography>
          </Box>

          <Divider sx={{ mb: 3 }} />
          
          <Stack spacing={2} sx={{ mb: 'auto' }}>
            {[
              t("benefits.solution.items.0"),
              t("benefits.solution.items.1"),
              t("benefits.solution.items.2"),
              t("benefits.solution.items.3"),
              t("benefits.solution.items.4")
            ].map((item, index) => (
              <Box 
                key={index} 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'flex-start'
                }}
              >
                <Box 
                  component="span" 
                  sx={{ 
                    color: 'success.main', 
                    mr: 1.5,
                    mt: 0.3,
                    flexShrink: 0
                  }}
                >
                  ✓
                </Box>
                <Typography variant="body2">{item}</Typography>
              </Box>
            ))}
          </Stack>
        </Box>
      </Grid>
    </Grid>
    
    {/* Call to action */}
    <Box sx={{ textAlign: 'center', mt: 6 }}>
      <Button 
        variant="outlined" 
        color="primary"
        endIcon={<ArrowForward />}
        sx={{ borderRadius: 3 }}
        href="#contact" 
      >
      {t("benefits.cta")}
      </Button>
    </Box>
  </Container>
</Box>
      
      {/* How It Works Section */}
      <Container id="how-it-works" sx={{ py: 8 }}>
  <Box sx={{ mb: 6, textAlign: 'center' }}>
    <Typography
      variant="h4"
      component="h2"
      fontWeight="bold"
      color="primary.main"
      sx={{ mb: 2 }}
    >
     {t("howItWorks.title")}
    </Typography>
    
    <Typography
      variant="body1"
      component="p"
      color="text.secondary"
      sx={{ maxWidth: 600, mx: 'auto' }}
    >
     {t("howItWorks.subtitle")}
    </Typography>
  </Box>
  
  <Box sx={{ position: 'relative' }}>
    {/* Connecting line */}
    <Box
      sx={{
        display: { xs: 'none', md: 'block' },
        position: 'absolute',
        top: 30,
        left: '15%',
        width: '70%',
        height: 3,
        bgcolor: 'grey.100',
        zIndex: 0
      }}
    />
    
    <Grid container spacing={3} justifyContent="center">
      {workflowSteps.map((step, index) => (
        <Grid item xs={12} sm={6} md={3} key={index}>
          <Fade in={true} style={{ transitionDelay: `${index * 150}ms` }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                position: 'relative',
                zIndex: 1
              }}
            >
              {/* Step number and icon circle */}
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: 70,
                  height: 70,
                  borderRadius: '50%',
                  bgcolor: 'white',
                  boxShadow: '0 4px 15px rgba(0,0,0,0.08)',
                  mb: 2,
                  position: 'relative',
                  border: '2px solid',
                  borderColor: 'primary.light'
                }}
              >
                {/* Step number badge */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: -8,
                    right: -8,
                    width: 28,
                    height: 28,
                    borderRadius: '50%',
                    bgcolor: 'primary.main',
                    color: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '0.875rem',
                    boxShadow: '0 2px 8px rgba(0,0,0,0.15)'
                  }}
                >
                  {index + 1}
                </Box>
                
                {/* Icon */}
                {step.icon}
              </Box>
              
              {/* Step content */}
              <Box
                sx={{
                  textAlign: 'center',
                  maxWidth: 220,
                  mx: 'auto',
                  bgcolor: 'white',
                  py: 2,
                  px: 1,
                  borderRadius: 1,
                  height: '100%'
                }}
              >
                <Typography 
                  variant="h6" 
                  fontWeight="bold" 
                  color="primary.main"
                  sx={{ mb: 1, fontSize: '1.1rem' }}
                >
                  {step.title}
                </Typography>
                <Typography 
                  variant="body2" 
                  color="text.secondary"
                  sx={{ fontSize: '0.9rem', lineHeight: 1.5 }}
                >
                  {step.description}
                </Typography>
              </Box>
            </Box>
          </Fade>
        </Grid>
      ))}
    </Grid>
    
    {/* Simple call to action */}
    <Box sx={{ mt: 6, textAlign: 'center' }}>
      <Button
        variant="contained"
        size="medium"
        color="primary"
        sx={{
          px: 3,
          py: 1,
          borderRadius: 2
        }}
        href="#contact" 
      >
      {t("howItWorks.cta")}
      </Button>
    </Box>
  </Box>
</Container>

      {/* Gallery */}
<Box sx={{ bgcolor: 'grey.900', color: 'white', py: 10 }} id="demos">
  <Container>
    <Typography
      variant="h3"
      component="h2"
      align="center"
      fontWeight="bold"
      gutterBottom
      sx={{ 
        fontSize: { xs: '1.75rem', sm: '2.25rem', md: '3rem' } // Responsive font size
      }}
    >
    {t("demos.title")}
    </Typography>

    <Typography
      variant="h6"
      component="p"
      align="center"
      sx={{ maxWidth: 800, mx: 'auto', mb: 8, opacity: 0.9, fontWeight: 'normal' }}
    >
     {t("demos.subtitle")}
    </Typography>

    <Grid 
      container 
      spacing={{ xs: 2, md: 4 }} 
      justifyContent="center"
    >
      {beforeAfterImages.map((image) => (
        <Grid 
          item 
          key={image.id}
          xs={12} 
          sm={6} 
          md={4}
        >
          <Box
            sx={{
              width: '100%',
              boxShadow: '0 5px 15px rgba(0,0,0,0.3)',
              borderRadius: 2,
              overflow: 'hidden',
              bgcolor: 'rgba(0,0,0,0.5)',
              height: '100%',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ display: 'flex', fflexDirection: { xs: 'column', sm: 'row' } }}>
              {/* Before image */}
              <Box sx={{ width: { xs: '100%', sm: '50%' },  position: 'relative' }}>
                <Box
                  component="img"
                  src={image.before}
                  alt="Before"
                  sx={{
                    width: '100%',
                    height: { xs: 150, sm: 200 },
                    objectFit: 'cover',
                    display: 'block'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    left: 8,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: '0.7rem',
                    fontWeight: 'bold'
                  }}
                >
                 {t("demos.before")}
                </Box>
              </Box>
              
              {/* After image */}
              <Box sx={{ width: { xs: '100%', sm: '50%' }, position: 'relative' }}>
                <Box
                  component="img"
                  src={image.after}
                  alt="After"
                  sx={{
                    width: '100%',
                    height: { xs: 150, sm: 200 },
                    objectFit: 'cover',
                    display: 'block'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: 8,
                    right: 8,
                    bgcolor: 'rgba(0,0,0,0.7)',
                    color: 'white',
                    px: 1.5,
                    py: 0.5,
                    borderRadius: 1,
                    fontSize: '0.7rem',
                    fontWeight: 'bold'
                  }}
                >
                 {t("demos.after")}
                </Box>
              </Box>
            </Box>

            <Box sx={{ p: 2, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
              <Typography
                variant="h6"
                fontWeight="bold"
                align="center"
                gutterBottom
                sx={{ fontSize: { xs: '0.9rem', sm: '1.1rem' } }}
              >
                {image.title}
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 'auto' }}>
                <Chip
                  label={image.improvement}
                  size="small"
                  color="secondary"
                  sx={{ fontWeight: 500, fontSize: '0.75rem' }}
                />
              </Box>
            </Box>
          </Box>
        </Grid>
      ))}
    </Grid>

    {/* <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
      <Button
        variant="outlined"
        color="secondary"
        size="large"
        sx={{
          px: 4,
          py: 1,
          borderRadius: 2,
          fontWeight: 'medium',
          borderWidth: 2,
          color: 'white',
          borderColor: 'secondary.main',
          '&:hover': {
            borderWidth: 2,
            backgroundColor: 'rgba(255,255,255,0.05)'
          }
        }}
      >
        Mehr Beispiele ansehen
      </Button>
    </Box> */}
  </Container>
</Box>
      
{/* Benefits Section with animated cards */}
<Container sx={{ py: 8 }}>
  <Typography 
    variant="h4" 
    component="h2" 
    align="center" 
    fontWeight="bold" 
    gutterBottom
  >
 {t("benefits.advantagesTitle")}
  </Typography>
  
  <Typography 
    variant="h6" 
    component="p" 
    align="center" 
    color="text.secondary" 
    sx={{ maxWidth: 800, mx: 'auto', mb: 6, fontWeight: 'normal' }}
  >
   {t("benefits.advantagesSubtitle")}
  </Typography>
  
  <Box sx={{ maxWidth: 800, mx: 'auto' }}>
    {benefits.map((benefit, index) => (
      <Box 
        key={benefit.id}
        sx={{ 
          borderBottom: '1px solid #e0e0e0',
          py: 3,
          '&:last-child': {
            borderBottom: 'none'
          }
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box 
            sx={{ 
              mr: 2.5,
              color: 'primary.main',
              display: 'flex'
            }}
          >
            {benefit.icon}
          </Box>
          
          <Box>
            <Typography variant="h6" component="h3" fontWeight="bold">
              {benefit.title}
            </Typography>
            
            <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
              {benefit.description}
            </Typography>
          </Box>
        </Box>
      </Box>
    ))}
  </Box>
</Container>


{/* Call to Action Section */}
<ContactForm/>
      
      {/* FAQ Section */}
      <FAQ/>
      {/* Footer */}
      <Footer/>
       {/* Background decoration */}
       <Box 
        sx={{ 
          position: 'absolute',
          top: '10%',
          right: '5%',
          width: '30%',
          height: '30%',
          opacity: 0.05,
          background: 'url(/dots-pattern.svg) repeat',
          clipPath: 'circle()'
        }} 
      />
     
      {/* Floating elements */}
      <StyledFab
        color="primary" 
        aria-label="scroll-to-top" 
        onClick={scrollToTop}
        sx={{ 
          display: showScrollTop ? 'flex' : 'none',
          animation: 'bounce 2s infinite'
        }}
      >
        <KeyboardArrowUp />
      </StyledFab>
      
      {/* WhatsApp chat button */}
      {/* <Zoom in={true}>
        <Fab 
          color="success" 
          aria-label="chat" 
          sx={{ 
            position: 'fixed', 
            bottom: theme.spacing(4), 
            left: theme.spacing(4),
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
          }}
        >
          <WhatsApp />
        </Fab>
      </Zoom>
       */}
      {/* Success message */}
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={() => setSuccessMessage(null)}
        message={successMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{
          '& .MuiSnackbarContent-root': {
            bgcolor: 'success.main',
            boxShadow: 3
          }
        }}
      />
    </Box>
  );
};

export default ProductBoosterLandingPage;
