import React from 'react';
import { useTranslation } from 'react-i18next';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  Button, 
  Divider, 
  Fab,
  Zoom 
} from '@mui/material';
import { AutoAwesome, WhatsApp, KeyboardArrowUp } from '@mui/icons-material';

// Custom styled Fab component can be defined here or imported
const StyledFab = props => <Fab {...props} />;

const Footer = ({ scrollToTop, showScrollTop, theme }) => {
  const { t } = useTranslation();
  const currentYear = new Date().getFullYear();
  
  // Only include essential legal links
  const legalLinks = [
    { name: 'Datenschutz', href: '/datenschutz' },
    { name: 'Impressum', href: '/impressum' },
    { name: 'AGB', href: '/agb' }
  ];

  return (
    <Box 
      component="footer"
      sx={{ 
        bgcolor: 'grey.900', 
        color: 'grey.300', 
        pt: 8, 
        pb: 4,
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4}>
          {/* Brand and Contact Information */}
          <Grid item xs={12} md={6}>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
              <AutoAwesome sx={{ mr: 1, color: 'primary.main' }} />
              <Typography variant="h5" component="h3" color="white" fontWeight="bold">
                Product Booster
              </Typography>
            </Box>
            
            <Typography variant="body2" sx={{ mb: 3, maxWidth: 450 }}>
            {t("footer.description")}
            </Typography>
            
            {/* <Box sx={{ mb: 4 }}>
              <Button 
                variant="contained" 
                color="primary" 
                startIcon={<WhatsApp />}
                size="small"
                href="https://wa.me/+49123456789" // Replace with your actual WhatsApp contact
                target="_blank"
                rel="noopener noreferrer"
              >
                WhatsApp Support
              </Button>
            </Box> */}
            
            <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Typography component="span" sx={{ fontSize: '1.5rem', mr: 1 }}>📧</Typography> 
              <Box component="a" href="mailto:info@productbooster.de" sx={{ color: 'inherit', textDecoration: 'none', '&:hover': { color: 'primary.main' } }}>
                info@productbooster.de
              </Box>
            </Typography>
          </Grid>
          
          {/* Legal Links */}
          {/* <Grid item xs={12} md={6}>
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={12} sm={6}>
                <Typography variant="subtitle1" component="h3" gutterBottom fontWeight="bold" color="white">
                  Rechtliches
                </Typography>
                
                {legalLinks.map((link, index) => (
                  <Typography
                    key={index}
                    component="a"
                    href={link.href}
                    variant="body2"
                    display="block"
                    sx={{ 
                      mb: 1.5, 
                      color: 'grey.400', 
                      textDecoration: 'none',
                      transition: 'all 0.2s ease',
                      '&:hover': { color: 'primary.main', transform: 'translateX(3px)' },
                      display: 'flex',
                      alignItems: 'center'
                    }}
                  >
                    <Box 
                      component="span" 
                      sx={{ 
                        width: 6, 
                        height: 6, 
                        borderRadius: '50%', 
                        bgcolor: 'primary.main', 
                        mr: 1.5, 
                        transition: 'all 0.2s ease',
                        opacity: 0.7
                      }} 
                    />
                    {link.name}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        
        <Divider sx={{ my: 4, bgcolor: 'grey.800' }} />
        
        <Box 
          sx={{ 
            display: 'flex', 
            flexDirection: { xs: 'column', sm: 'row' },
            justifyContent: { xs: 'center', md: 'flex-start' },
            alignItems: { xs: 'center', sm: 'flex-start' }
          }}
        >
          <Typography variant="body2" color="grey.500" sx={{ mb: { xs: 2, sm: 0 } }}>
          {t("footer.copyright", { year: new Date().getFullYear() })}
          </Typography>
          
          <Typography variant="body2" color="grey.600">
          {t("footer.disclaimer")}
          </Typography>
        </Box>
      </Container>
      
     
    </Box>
  );
};

export default Footer;
