import React, { useState } from 'react';
import {
    Drawer,
    List,
    ListItem,
    ListItemText,
    IconButton,
    Box,
    Divider
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';

export const MobileMenu = ({ menuItems }) => {
    const [open, setOpen] = useState(false);

    const toggleDrawer = (isOpen) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setOpen(isOpen);
    };

    return (
        <>
            <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(true)}
                sx={{ display: { xs: 'flex', md: 'none' } }}
            >
                <MenuIcon />
            </IconButton>

            <Drawer
                anchor="right"
                open={open}
                onClose={toggleDrawer(false)}
            >
                <Box
                    sx={{
                        width: 250,
                        height: '100%',
                        bgcolor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                    role="presentation"
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                >
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        p: 1
                    }}>
                        <IconButton onClick={toggleDrawer(false)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>

                    <Divider />

                    <List>
                        {menuItems.map((item) => (
                            <ListItem
                                button
                                key={item.label}
                                component="a"
                                href={item.href}
                                sx={{ py: 2 }}
                            >
                                <ListItemText
                                    primary={item.label}
                                    sx={{ textAlign: 'center' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Drawer>
        </>
    );
};