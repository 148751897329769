// src/components/StyledComponents.js
import { Fab, Typography } from '@mui/material';
import { Box, styled } from '@mui/system';

export const GradientText = styled(Typography)(({ theme }) => ({
    background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`,
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    backgroundClip: 'text',
    color: 'transparent',
}));

export const HeroGradientBox = styled(Box)(({ theme }) => ({
    background: `linear-gradient(135deg, ${theme.palette.primary.dark} 0%, ${theme.palette.primary.main} 100%)`,
    borderRadius: theme.shape.borderRadius * 2,
    overflow: 'hidden',
    position: 'relative',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        background: 'url(/dots-pattern.svg) repeat',
        opacity: 0.1,
    }
}));

export const BeforeAfterImage = styled(Box)(({ theme }) => ({
    position: 'relative',
    cursor: 'pointer',
    overflow: 'hidden',
    borderRadius: theme.shape.borderRadius,
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
        transform: 'scale(1.02)',
        boxShadow: '0 8px 20px rgba(0, 0, 0, 0.2)',
    }
}));

export const SliderDivider = styled(Box)(({ theme, position }) => ({
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: `${position}%`,
    width: '4px',
    backgroundColor: '#ffffff',
    boxShadow: '0 0 10px rgba(0,0,0,0.5)',
    zIndex: 2,
    cursor: 'col-resize',
    '&::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        boxShadow: '0 0 10px rgba(0,0,0,0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
}));

export const AnimatedFeatureCard = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
    borderRadius: theme.shape.borderRadius * 2,
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.08)',
    height: '100%',
    transition: 'all 0.4s ease-in-out',
    border: '2px solid transparent',
    backgroundColor: '#ffffff',
    position: 'relative',
    overflow: 'hidden',
    '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        height: '4px',
        background: `linear-gradient(90deg, ${theme.palette.primary.main}, ${theme.palette.secondary.main})`,
        transform: 'scaleX(0)',
        transformOrigin: 'left',
        transition: 'transform 0.4s ease-in-out',
    },
    '&:hover': {
        transform: 'translateY(-8px)',
        boxShadow: '0 12px 30px rgba(0, 0, 0, 0.12)',
        border: `2px solid ${theme.palette.grey[100]}`,
        '&::before': {
            transform: 'scaleX(1)',
        }
    }
}));

export const StyledFab = styled(Fab)(({ theme }) => ({
    position: 'fixed',
    bottom: theme.spacing(4),
    right: theme.spacing(4),
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    zIndex: 1000,
}));
