import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from '@mui/material';
import { ExpandMore, EmailOutlined } from '@mui/icons-material';

// FAQ data object

const FAQ = () => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const faqData =  t('faq.questions', { returnObjects: true });

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Container sx={{ py: 10 }}>
      <Typography 
        variant="h4" 
        component="h2" 
        align="center" 
        fontWeight="bold" 
        gutterBottom
        sx={{ fontSize: { xs: '2rem', md: '2.5rem' } }}
      >
         {t('faq.title')}
      </Typography>
      
      <Typography 
        variant="h6" 
        component="p" 
        align="center" 
        color="text.secondary" 
        sx={{ maxWidth: 600, mx: 'auto', mb: 6 }}
      >
        {t('faq.subtitle')}
      </Typography>
      
      <Box sx={{ maxWidth: 900, mx: 'auto' }}>
        {faqData.map((faq) => (
          <Accordion 
            key={faq.id}
            expanded={expanded === `panel${faq.id}`}
            onChange={handleChange(`panel${faq.id}`)}
            disableGutters 
            elevation={0} 
            sx={{ 
              mb: 1.5, 
              border: '1px solid rgba(0,0,0,0.08)', 
              borderRadius: '8px !important',
              '&:before': { display: 'none' },
              overflow: 'hidden'
            }}
          >
            <AccordionSummary 
              expandIcon={<ExpandMore />}
              sx={{ 
                bgcolor: 'rgba(0,0,0,0.02)',
                '&:hover': { bgcolor: 'rgba(0,0,0,0.04)' } 
              }}
            >
              <Typography fontWeight="medium">{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography color="text.secondary">
                {faq.answer}
              </Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </Box>
      
      {/* <Box 
        sx={{ 
          mt: 5, 
          textAlign: 'center',
          maxWidth: 500,
          mx: 'auto',
          px: 3,
          py: 4,
          borderRadius: 3,
          background: 'linear-gradient(135deg, rgba(25,118,210,0.05) 0%, rgba(66,66,66,0.05) 100%)',
        }}
      >
        <Typography variant="h6" fontWeight="medium" gutterBottom>
          {faqData.contactSection.title}
        </Typography>
        <Typography variant="body2" paragraph color="text.secondary">
          {faqData.contactSection.description}
        </Typography>
        <Button 
          variant="outlined" 
          color="primary" 
          startIcon={<EmailOutlined />}
          sx={{ borderRadius: 2, py: 1, px: 2 }}
        >
          {faqData.contactSection.buttonText}
        </Button>
      </Box> */}
    </Container>
  );
};

export default FAQ;
