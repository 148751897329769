import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box, Container, Typography, TextField, FormControl,
    InputLabel, Select, MenuItem, Button, FormControlLabel,
    Checkbox, Grid, FormGroup, CircularProgress
} from '@mui/material';
import { Send, Lock } from '@mui/icons-material';

const ContactForm = () => {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        options: [], // Array for multiple optimizations
        quantity: '',
        deliveryTime: '',
        imageUrls: '',
        description: '',
        termsAgreed: false
    });

    // Loading and error states
    const [isLoading, setIsLoading] = useState(false);
    const [formError, setFormError] = useState(null);
    const [formSuccess, setFormSuccess] = useState(false);

    // Handle input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Handle checkbox changes for optimization options
    const handleOptimizationChange = (e) => {
        const { value, checked } = e.target;

        setFormData(prevData => {
            if (checked) {
                return {
                    ...prevData,
                    options: [...prevData.options, value]
                };
            } else {
                return {
                    ...prevData,
                    options: prevData.options.filter(option => option !== value)
                };
            }
        });
    };

    // Handle terms agreement checkbox
    const handleTermsChange = (e) => {
        setFormData({
            ...formData,
            termsAgreed: e.target.checked
        });
    };

    // Form submission handler
    const handleFormSubmit = async (e) => {
        e.preventDefault();

        // Validate form
        if (!formData.termsAgreed) {
            setFormError(t("contact.form.error"));
            return;
        }

        try {
            setIsLoading(true);
            setFormError(null);

            // Prepare payload according to your backend expectations
            const payload = {
                name: formData.name,
                email: formData.email,
                phone: formData.phone,
                options: formData.options.join(', '),
                quantity: formData.quantity,
                deliveryTime: formData.deliveryTime,
                imageUrls: formData.imageUrls,
                description: formData.description
            };

            // Send data to your backend
            const response = await fetch("http://localhost:8000/retail/hubspot/submit/", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });

            const data = await response.json();

            if (!response.ok) {
                throw new Error(data.error || 'Ein Fehler ist aufgetreten');
            }

            // Handle success
            setFormSuccess(true);
            // Optionally reset form
            setFormData({
                name: '',
                email: '',
                phone: '',
                options: [],
                quantity: '',
                deliveryTime: '',
                imageUrls: '',
                description: '',
                termsAgreed: false
            });

            // Scroll to the top of the component
            window.scrollTo({
                top: document.getElementById('contact').offsetTop,
                behavior: 'smooth'
            });


        } catch (error) {
            console.error('Form submission error:', error);
            setFormError(error.message || 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.');
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Box
            id="contact"
            sx={{
                bgcolor: 'grey.900',
                color: 'white',
                py: 10,
                position: 'relative',
                overflow: 'hidden'
            }}
        >
            <Container maxWidth="lg" sx={{ position: 'relative', zIndex: 2 }}>
                <Box
                    sx={{
                        maxWidth: 700,
                        mx: 'auto',
                        textAlign: 'center',
                        px: { xs: 2, md: 4 },
                        py: { xs: 4, md: 6 },
                        borderRadius: 4,
                        position: 'relative',
                        '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            background: 'linear-gradient(135deg, rgba(25,118,210,0.4) 0%, rgba(66,66,66,0.1) 100%)',
                            borderRadius: 4,
                        }
                    }}
                >
                    <Typography variant="h4" component="h2" fontWeight="bold" gutterBottom>
                        {t("contact.title")}
                    </Typography>

                    <Typography
                        variant="h6"
                        component="p"
                        fontWeight="normal"
                        sx={{ mb: 5, maxWidth: 600, mx: 'auto', opacity: 0.9 }}
                    >
                        {t("contact.subtitle")}
                    </Typography>

                    {formSuccess ? (
                        <Box
                            sx={{
                                bgcolor: 'success.dark',
                                p: 4,
                                borderRadius: 2,
                                maxWidth: 500,
                                mx: 'auto',
                                textAlign: 'center'
                            }}
                        >
                            <Typography variant="h5" gutterBottom>{t("contact.success")}</Typography>
                            <Typography>
                                {t("contact.successMessage")}
                            </Typography>
                        </Box>
                    ) : (
                        <Box
                            component="form"
                            onSubmit={handleFormSubmit}
                            sx={{
                                maxWidth: 500,
                                mx: 'auto',
                                textAlign: 'left',
                                bgcolor: 'rgba(0,0,0,0.2)',
                                p: { xs: 2, sm: 4 },
                                borderRadius: 2
                            }}
                        >
                            {/* Error message if submission failed */}
                            {formError && (
                                <Box sx={{
                                    bgcolor: 'error.dark',
                                    p: 2,
                                    mb: 3,
                                    borderRadius: 1,
                                    color: 'white'
                                }}>
                                    <Typography>{formError}</Typography>
                                </Box>
                            )}

                            {/* Contact Information */}
                            <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                sx={{ mb: 2, color: 'rgba(255,255,255,0.9)' }}
                            >
                                {t("contact.form.contactInfo")}
                            </Typography>

                            <TextField
                                fullWidth
                                label={t("contact.form.name")}
                                variant="outlined"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                required
                                sx={{
                                    mb: 2,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    input: { color: 'white' },
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    }
                                }}
                            />

                            <TextField
                                fullWidth
                                label={t("contact.form.email")}
                                variant="outlined"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                required
                                sx={{
                                    mb: 2,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    input: { color: 'white' },
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    }
                                }}
                            />

                            <TextField
                                fullWidth
                                label={t("contact.form.phone")}
                                variant="outlined"
                                name="phone"
                                value={formData.phone}
                                onChange={handleInputChange}
                                sx={{
                                    mb: 3,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    input: { color: 'white' },
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    }
                                }}
                            />

                            {/* Project Details */}
                            <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                sx={{ mb: 2, mt: 1, color: 'rgba(255,255,255,0.9)' }}
                            >
                                {t("contact.form.optimizationOptions")}
                            </Typography>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                    borderRadius: 1,
                                }}
                            >
                                <FormGroup
                                    sx={{
                                        mb: 2,
                                        bgcolor: 'rgba(255, 255, 255, 0.05)',
                                        borderRadius: 1,
                                        p: 2,
                                        border: '1px solid rgba(255, 255, 255, 0.3)'
                                    }}
                                >
                                    <Grid container spacing={1}>
                                        {[
                                            { value: "amazon-marketplace", label: t("contact.form.options.amazon") },
                                            { value: "ebay-marketplace", label: t("contact.form.options.ebay") },
                                            { value: "online-shop", label: t("contact.form.options.shop") },
                                            { value: "white-background", label: t("contact.form.options.background") },
                                            { value: "lifestyle", label: t("contact.form.options.lifestyle") },
                                            { value: "ghost-mannequin", label: t("contact.form.options.mannequin") },
                                            { value: "infographic", label: t("contact.form.options.infographic") },
                                            { value: "social-media", label: t("contact.form.options.social") },
                                            { value: "custom", label: t("contact.form.options.custom") }
                                        ].map((option) => (
                                            <Grid item xs={12} sm={6} md={4} key={option.value}>
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            value={option.value}
                                                            checked={formData.options.includes(option.value)}
                                                            onChange={handleOptimizationChange}
                                                            sx={{
                                                                color: 'rgba(255, 255, 255, 0.7)',
                                                                '&.Mui-checked': {
                                                                    color: 'primary.main',
                                                                }
                                                            }}
                                                        />
                                                    }
                                                    label={option.label}
                                                    sx={{
                                                        color: 'white',
                                                        '& .MuiFormControlLabel-label': {
                                                            fontSize: '0.9rem'
                                                        }
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </FormGroup>
                            </FormControl>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    mb: 2,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    },
                                    '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
                                }}
                            >
                                <InputLabel>{t("contact.form.quantity.label")}</InputLabel>
                                <Select
                                    label={t("contact.form.quantity.label")}
                                    name="quantity"
                                    value={formData.quantity}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <MenuItem value="1-10">{t("contact.form.quantity.options.1-10")}</MenuItem>
                                    <MenuItem value="11-30">{t("contact.form.quantity.options.11-30")}</MenuItem>
                                    <MenuItem value="31-50">{t("contact.form.quantity.options.31-50")}</MenuItem>
                                    <MenuItem value="51-100">{t("contact.form.quantity.options.51-100")}</MenuItem>
                                    <MenuItem value="100+">{t("contact.form.quantity.options.100+")}</MenuItem>
                                    <MenuItem value="ongoing">{t("contact.form.quantity.options.ongoing")}</MenuItem>
                                </Select>
                            </FormControl>

                            <FormControl
                                fullWidth
                                variant="outlined"
                                sx={{
                                    mb: 3,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        color: 'white',
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    },
                                    '& .MuiSvgIcon-root': { color: 'rgba(255, 255, 255, 0.7)' }
                                }}
                            >
                                <InputLabel>{t("contact.form.deliveryTime.label")}</InputLabel>
                                <Select
                                    label={t("contact.form.deliveryTime.label")}
                                    name="deliveryTime"
                                    value={formData.deliveryTime}
                                    onChange={handleInputChange}
                                    required
                                >
                                    <MenuItem value="standard">{t("contact.form.deliveryTime.options.standard")}</MenuItem>
                                    <MenuItem value="express">{t("contact.form.deliveryTime.options.express")}</MenuItem>
                                    <MenuItem value="rush">{t("contact.form.deliveryTime.options.rush")}</MenuItem>
                                    <MenuItem value="super-rush">{t("contact.form.deliveryTime.options.super-rush")}</MenuItem>
                                </Select>
                            </FormControl>

                            {/* Image Source Selection */}
                            <Typography
                                variant="subtitle1"
                                fontWeight="medium"
                                sx={{ mb: 2, mt: 1, color: 'rgba(255,255,255,0.9)' }}
                            >
                                {t("contact.form.imageSource")}
                            </Typography>

                            {/* URL Input Option */}
                            <TextField
                                fullWidth
                                label={t("contact.form.imageUrls")}
                                variant="outlined"
                                multiline
                                name="imageUrls"
                                value={formData.imageUrls}
                                onChange={handleInputChange}
                                rows={3}
                                placeholder="https://example.com/image1.jpg&#10;https://example.com/image2.jpg"
                                sx={{
                                    mb: 3,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    '& .MuiInputBase-root': { color: 'white' },
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    }
                                }}
                            />

                            {/* Product Description */}
                            <TextField
                                fullWidth
                                multiline
                                rows={3}
                                label={t("contact.form.description")}
                                variant="outlined"
                                name="description"
                                value={formData.description}
                                onChange={handleInputChange}
                                sx={{
                                    mb: 3,
                                    bgcolor: 'rgba(255, 255, 255, 0.05)',
                                    borderRadius: 1,
                                    '& .MuiInputBase-root': { color: 'white' },
                                    '& label': { color: 'rgba(255, 255, 255, 0.7)' },
                                    '& .MuiOutlinedInput-root': {
                                        '& fieldset': { borderColor: 'rgba(255, 255, 255, 0.3)' },
                                        '&:hover fieldset': { borderColor: 'rgba(255, 255, 255, 0.5)' },
                                    }
                                }}
                            />

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        required
                                        checked={formData.termsAgreed}
                                        onChange={handleTermsChange}
                                        sx={{
                                            color: 'rgba(255, 255, 255, 0.7)',
                                            '&.Mui-checked': {
                                                color: 'primary.main',
                                            },
                                        }}
                                    />
                                }
                                label={t("contact.form.termsAgreed")}
                                sx={{ mb: 2 }}
                            />

                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                color="secondary"
                                size="large"
                                disabled={isLoading}
                                sx={{
                                    py: 1.8,
                                    fontSize: '1rem',
                                    fontWeight: 'bold',
                                    boxShadow: '0 8px 20px rgba(0, 0, 0, 0.3)'
                                }}
                                endIcon={isLoading ? <CircularProgress size={24} color="inherit" /> : <Send />}
                            >
                                {isLoading ? t("contact.form.sending") : t("contact.form.submit")}
                            </Button>
                        </Box>
                    )}

                    <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Lock sx={{ fontSize: 16, mr: 1, color: 'rgba(255, 255, 255, 0.6)' }} />
                        <Typography variant="body2" sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
                            {t("contact.form.security")}
                        </Typography>
                    </Box>
                </Box>
            </Container>

            {/* Background decoration */}
            <Box
                sx={{
                    position: 'absolute',
                    top: -100,
                    left: -100,
                    width: 500,
                    height: 500,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(25,118,210,0.4) 0%, rgba(25,118,210,0) 70%)',
                }}
            />

            <Box
                sx={{
                    position: 'absolute',
                    bottom: -150,
                    right: -150,
                    width: 600,
                    height: 600,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(25,118,210,0.3) 0%, rgba(25,118,210,0) 70%)',
                }}
            />
        </Box>
    );
};

export default ContactForm;
