import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Menu,
  MenuItem,
  Typography,
  Tooltip,
  ListItemIcon,
  ListItemText,
  useMediaQuery,
  useTheme
} from '@mui/material';
import { KeyboardArrowDown, Check as CheckIcon } from '@mui/icons-material';

// Language data with country codes for flags
const languages = [
  { code: 'de', name: 'Deutsch', countryCode: 'de' },
  { code: 'en', name: 'English', countryCode: 'gb' },
  { code: 'ru', name: 'Русский', countryCode: 'ru' },
  { code: 'fr', name: 'Français', countryCode: 'fr' },
  { code: 'it', name: 'Italiano', countryCode: 'it' },
  { code: 'es', name: 'Español', countryCode: 'es' }
];

const LanguageSwitcher = () => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = () => {
    setAnchorEl(null);
  };
  
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    handleClose();
  };
  
  // Current language code
  const currentLang = i18n.language || 'de';
  
  // Shortened language code (in case i18n returns 'en-US' or similar)
  const shortLang = currentLang.split('-')[0];
  
  // Find current language data
  const currentLanguage = languages.find(lang => lang.code === shortLang) || languages[0];

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
      <Tooltip title={t('languageSwitcher.title')}>
        <Box 
          sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            cursor: 'pointer',
            borderRadius: 1,
            '&:hover': {
              bgcolor: 'rgba(0, 0, 0, 0.04)'
            },
            p: 0.5
          }}
          onClick={handleClick}
        >
          <Box 
            component="img"
            src={`https://flagcdn.com/20x15/${currentLanguage.countryCode}.png`}
            srcSet={`https://flagcdn.com/40x30/${currentLanguage.countryCode}.png 2x`}
            alt={currentLanguage.name}
            sx={{ 
              width: isMobile ? 18 : 20,
              height: 'auto',
              borderRadius: 0.5,
              boxShadow: '0 0 0 1px rgba(0,0,0,0.1)'
            }}
          />
          <KeyboardArrowDown 
            fontSize="small" 
            sx={{ 
              ml: 0.5,
              fontSize: isMobile ? '1rem' : '1.2rem',
              opacity: 0.7
            }}
          />
        </Box>
      </Tooltip>
      
      {/* Language dropdown menu */}
      <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'language-button',
        }}
        PaperProps={{
          elevation: 3,
          sx: {
            minWidth: 180,
            mt: 1.5,
            overflow: 'hidden',
            borderRadius: 1.5,
            '& .MuiMenuItem-root': {
              py: 1,
            },
          },
        }}
      >
        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ 
            px: 2, 
            py: 1, 
            fontWeight: 'medium',
            bgcolor: 'rgba(0,0,0,0.02)',
            borderBottom: '1px solid rgba(0,0,0,0.08)'
          }}
        >
          {t('languageSwitcher.title')}
        </Typography>
        
        {languages.map((lng) => (
          <MenuItem 
            key={lng.code} 
            onClick={() => changeLanguage(lng.code)}
            selected={shortLang === lng.code}
            sx={{
              borderLeft: shortLang === lng.code ? 2 : 0,
              borderColor: 'primary.main',
              pl: shortLang === lng.code ? 1.8 : 2,
              py: 1.5,
              bgcolor: shortLang === lng.code ? 'rgba(25,118,210,0.04)' : 'transparent'
            }}
          >
            <ListItemIcon sx={{ minWidth: 36 }}>
              <Box 
                component="img"
                src={`https://flagcdn.com/20x15/${lng.countryCode}.png`}
                srcSet={`https://flagcdn.com/40x30/${lng.countryCode}.png 2x`}
                alt={lng.name}
                sx={{ 
                  width: 20,
                  height: 'auto',
                  display: 'block',
                  borderRadius: 0.5,
                  boxShadow: '0 0 0 1px rgba(0,0,0,0.1)'
                }}
              />
            </ListItemIcon>
            <ListItemText>
              {t(`languageSwitcher.languages.${lng.code}`)}
            </ListItemText>
            {shortLang === lng.code && <CheckIcon fontSize="small" color="primary" sx={{ ml: 1 }} />}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default LanguageSwitcher;