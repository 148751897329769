// src/theme.js
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

// Create the base theme
let theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
            light: '#e3f2fd',
            dark: '#0d47a1',
        },
        secondary: {
            main: '#f50057',
            light: '#fce4ec',
            dark: '#c51162',
        },
        grey: {
            50: '#fafafa',
            100: '#f5f5f5',
            800: '#424242',
            900: '#212121',
        },
        error: {
            main: '#f44336',
            light: '#ffebee',
        },
        success: {
            main: '#4caf50',
            light: '#e8f5e9',
        },
        text: {
            primary: '#212121',
            secondary: '#757575',
        },
        background: {
            paper: '#ffffff',
        },
    },
    shape: {
        borderRadius: 4,
    },
    typography: {
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        // Base typography settings
        h1: {
            fontWeight: 700,
            lineHeight: 1.2,
        },
        h2: {
            fontWeight: 700,
            lineHeight: 1.3,
        },
        h3: {
            fontWeight: 600,
            lineHeight: 1.3,
        },
        h4: {
            fontWeight: 600,
            lineHeight: 1.4,
        },
        h5: {
            fontWeight: 500,
            lineHeight: 1.4,
        },
        h6: {
            fontWeight: 500,
            lineHeight: 1.5,
        },
        subtitle1: {
            lineHeight: 1.5,
        },
        subtitle2: {
            lineHeight: 1.5,
        },
        body1: {
            lineHeight: 1.5,
        },
        body2: {
            lineHeight: 1.6,
        },
    },
    // Custom breakpoints can be defined here if needed
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
    // Custom spacing for mobile-friendly layouts
    spacing: 8, // Base spacing unit (8px)
    // Mobile-specific overrides
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    paddingLeft: {
                        xs: 16,
                        sm: 24,
                    },
                    paddingRight: {
                        xs: 16,
                        sm: 24,
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    '@media (max-width:600px)': {
                        minHeight: 48, // Larger touch target on mobile
                    },
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                input: {
                    '@media (max-width:600px)': {
                        fontSize: '16px', // Prevents iOS zoom on focus
                    },
                },
            },
        },
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    '@media (max-width:600px)': {
                        minHeight: 56, // Larger touch target for mobile
                    },
                },
                content: {
                    '@media (max-width:600px)': {
                        margin: '12px 0', // More space on mobile
                    },
                },
            },
        },
    },
});

// Apply responsive font sizes
theme = responsiveFontSizes(theme, {
    breakpoints: ['xs', 'sm', 'md', 'lg', 'xl'],
    factor: 2, // Increased scaling factor for more dramatic size changes
    variants: [
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'subtitle1',
        'subtitle2',
        'body1',
        'body2'
    ]
});

// Additional custom responsive adjustments
theme.typography.h1 = {
    ...theme.typography.h1,
    [theme.breakpoints.down('sm')]: {
        fontSize: '2.5rem', // Custom size for mobile
    },
};

theme.typography.h2 = {
    ...theme.typography.h2,
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem', // Custom size for mobile
    },
};

// You can add more custom overrides for specific variants if needed

export default theme;