import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Grid, Typography, Card, CardMedia, CardContent } from '@mui/material';
import { GradientText } from './styledComponents';
import { useTheme } from '@mui/material/styles';

const MediaGrid = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const videoRef = useRef(null);

    useEffect(() => {
        // Auto-play video when component mounts
        if (videoRef.current) {
            videoRef.current.play().catch(error => {
                // Handle any autoplay restrictions (common in some browsers)
                console.log("Autoplay prevented:", error);
            });
        }
    }, []);

    return (
        <Box sx={{ py: 10, mt: 5 }}>
            <Typography
                variant="h4"
                component="h2"
                fontWeight="bold"
                align="center"
                gutterBottom

            >
                {t("media.title")}
            </Typography>
            <Typography
                variant="body1"
                color="text.secondary"
                sx={{
                    maxWidth: 700, mx: 'auto',
                    mb: 4

                }}
            >
                {t("media.subtitle")}
            </Typography>
            <Grid container spacing={3}>
                {/* Original Image */}
                <Grid item size={{ xs: 12, sm: 6, md: 4 }} sx={{ display: 'flex', p: { xs: 6, sm: 4, md: 2 } }}>
                    <Card sx={{
                        backgroundColor: 'grey.900',
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                    }}>
                        <Box sx={{
                            position: 'relative',
                            paddingTop: '100%', // 4:3 aspect ratio
                            width: '100%'
                        }}>
                            <CardMedia
                                component="img"
                                alt="Original Product Image"
                                image="/assets/hero-1.jpg"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    left: 16,
                                    bgcolor: 'rgba(0,0,0,0.6)',
                                    color: 'white',
                                    px: 1.5,
                                    py: 0.5,
                                    borderRadius: 1,
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {t("media.cards.original.badge")}
                            </Box>
                        </Box>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h6" component="h3">
                                {t("media.cards.original.title")}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {t("media.cards.original.description")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Optimized Image */}
                <Grid item size={{ xs: 12, sm: 6, md: 4 }} sx={{ display: 'flex', p: { xs: 6, sm: 4, md: 2 } }}>
                    <Card sx={{
                        backgroundColor: 'grey.900',
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                    }}>
                        <Box sx={{
                            position: 'relative',
                            paddingTop: '100%', // 4:3 aspect ratio
                            width: '100%'
                        }}>
                            <CardMedia
                                component="img"
                                alt="Optimized Product Image"
                                image="/assets/hero-2.jpg"
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    left: 16,
                                    bgcolor: 'rgba(0,0,0,0.6)',
                                    color: 'white',
                                    px: 1.5,
                                    py: 0.5,
                                    borderRadius: 1,
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {t("media.cards.optimized.badge")}
                            </Box>
                        </Box>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h6" component="h3">
                                {t("media.cards.optimized.title")}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {t("media.cards.optimized.description")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Video Comparison */}
                <Grid item size={{ xs: 12, sm: 6, md: 4 }} sx={{ display: 'flex', p: { xs: 6, sm: 4, md: 2 } }}>
                    <Card sx={{
                        backgroundColor: 'grey.900',
                        color: 'white',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                    }}>
                        <Box sx={{
                            position: 'relative',
                            paddingTop: '100%', // 4:3 aspect ratio
                            width: '100%'
                        }}>
                            <CardMedia
                                component="video"
                                ref={videoRef}
                                src="/assets/hero-3.mp4"
                                autoPlay
                                loop
                                muted
                                playsInline
                                sx={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover'
                                }}
                            />
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: 16,
                                    left: 16,
                                    bgcolor: 'rgba(0,0,0,0.6)',
                                    color: 'white',
                                    px: 1.5,
                                    py: 0.5,
                                    borderRadius: 1,
                                    fontSize: '0.75rem',
                                    fontWeight: 'bold'
                                }}
                            >
                                {t("media.cards.video.badge")}
                            </Box>
                        </Box>
                        <CardContent sx={{ flexGrow: 1 }}>
                            <Typography gutterBottom variant="h6" component="h3">
                                {t("media.cards.video.title")}
                            </Typography>
                            <Typography variant="body2" sx={{ color: 'white' }}>
                                {t("media.cards.video.description")}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default MediaGrid;
